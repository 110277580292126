import type { Routes } from '@angular/router';

import { PermissionsGuard } from '@clover/core/guards/permissions.guard';

import { AuditTrailEntity } from './entities';

export type AuditTrailEntityRoute = 'company' | 'task' | 'workflow';

export const AUDIT_TRAIL_BASE_URL = 'audit-trail';

export const auditTrailPageRoutes: Routes = [
  {
    path: '',
    loadComponent: async () =>
      await import('./audit-trail-page.component').then(({ AuditTrailPageComponent }) => AuditTrailPageComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'company',
      },
      {
        path: 'company',
        loadComponent: async () =>
          await import('./company-audit-trail/company-audit-trail.component').then(
            ({ CompanyAuditTrailComponent }) => CompanyAuditTrailComponent,
          ),
      },
      {
        path: 'task',
        loadComponent: async () =>
          await import('./task-audit-trail/task-audit-trail.component').then(
            ({ TaskAuditTrailComponent }) => TaskAuditTrailComponent,
          ),
        canActivate: [() => false],
      },
      {
        path: 'workflow',
        loadComponent: async () =>
          await import('./workflow-audit-trail/workflow-audit-trail.component').then(
            ({ WorkflowAuditTrailComponent }) => WorkflowAuditTrailComponent,
          ),
        canActivate: [() => false],
      },
    ],
    data: {
      permissions: ['AuditTrail_View'],
    },
    canActivate: [PermissionsGuard],
  },
];

export function mapEntityToRoute(entiry: AuditTrailEntity): AuditTrailEntityRoute {
  switch (entiry) {
    case AuditTrailEntity.Company:
      return 'company';
    case AuditTrailEntity.Task:
      return 'task';
    case AuditTrailEntity.Workflow:
      return 'workflow';
  }
}

export function mapRouteToEntity(route: AuditTrailEntityRoute): AuditTrailEntity {
  switch (route) {
    case 'company':
      return AuditTrailEntity.Company;
    case 'task':
      return AuditTrailEntity.Task;
    case 'workflow':
      return AuditTrailEntity.Workflow;
  }
}
